.banner {
    position: relative;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: left;
  background-image: url('img/banner-gol.webp');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;

    overflow: hidden;
  }
  .banner svg {
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: auto;
  }
  
  .banner-content {
    text-align: left;
    padding: 80px;
    width: 50%;
    color: #242424;
    position: absolute;
    z-index: 2;

  }
  
  .title {
    font-size: 5em;
    margin-bottom: 0.1em;
    text-shadow: 0 1px 0 #ccc, 
    0 2px 0 #c9c9c9,
    0 3px 0 #bbb,
    0 4px 0 #b9b9b9,
    0 5px 0 #aaa,
    0 6px 1px rgba(0,0,0,.1),
    0 0 5px rgba(0,0,0,.1),
    0 1px 3px rgba(0,0,0,.3),
    0 3px 5px rgba(0,0,0,.2),
    0 5px 10px rgba(0,0,0,.25),
    0 10px 10px rgba(0,0,0,.2),
    0 20px 20px rgba(0,0,0,.15);
    
  }
  
  .subtitle {
    font-size: 21px;
    margin-bottom: 1em;
    color: white;
  }
  
  .button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.5em;
    text-decoration: none;
    color: #fff;
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    z-index: 1;
    position: relative;
  }
  
  .button-two {
    display: inline-block;
    margin-left: 10px;
    padding: 10px 20px;
    font-size: 1.5em;
    text-decoration: none;
    color: #ffffff;
    background-color: #0093E9;
    background-image: linear-gradient(315deg, #0093E9 0%, #80D0C7 100%);
    
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  .button:hover{
    color: #b9b3d0;
  }
  .button-two:hover{
    color: #b9b3d0;
  }

  #particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  /*ico*/
.ico{
  right: 0px;
  margin-top: 20px;
  position: absolute;
}
stop .uno{
  color:#b9b3d0;
  opacity: 1;
}
stop .dos{
  color:#7bb5bf;
  opacity: 1;
}

@media (max-width: 768px){
  .banner{
    background-image: url('img/banner-movil-gol.webp');
  }
    .title{
        font-size: 50px;
    }
    .subtitle{
        font-size: 17px;
    }
    .banner-content{
        padding-left: 20px;
        width: 80%;
    }
    .ico {
      position: absolute;
      bottom: 50px;
      left:30px;
      width: 350px;
      height: auto;
    }
    .button{
      font-size: 20px;
    }
    .button-two{
      font-size: 20px;
    }
  }