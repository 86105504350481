/* Estilos para el modal principal */
.modal {
  display: none;
}

.modal.show {
  display: block;
}

.modal {
  position: fixed;
  z-index: 199;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -31%);
  background-color: #fefefe;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
  z-index: 4;
}

.modal-content h2 {
  margin-bottom: 0px;
}

.modal-content p {
  margin-top: 0px;
  margin-bottom: 30px;
}

.modal-content img {
  width: 40px;
  margin-bottom: 5px;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.modal-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.accept {
  background-color: #0056f7;
}


.customize {
  background-color: #0056f7;
}

.modal-button:hover {
  opacity: 0.8;
}


#politicaModal {
  display: none;
}

#politicaModal.show {
  display: block;
  z-index: 199;
}

#politicaModal {
  position: fixed;
  z-index: 20; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

#politicaModal .modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -31%);
  background-color: #fefefe;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

#politicaModal .modal-content h2 {
  margin-bottom: 20px;
}

.boton-p {
  cursor: pointer;
  color: rgb(116, 10, 134);
}

.boton-p:hover {
  cursor: pointer;
  color: rgb(170, 0, 200);
}

.close {
  cursor: pointer;
  text-align: left;
}


#customizeModal {
  display: none;
}

#customizeModal.show {
  display: block;
  z-index: 199;
}

#customizeModal {
  position: fixed;
  z-index: 21; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

#customizeModal .modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  width: 90%;
  max-width: 600px;
  max-height: 80%; 
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

#customizeModal .modal-content h2 {
  margin-bottom: 20px;
}

#customizeModal .checkbox-container {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
}

#customizeModal .checkbox-container label {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
}

#customizeModal .checkbox-container label span {
  margin-bottom: 5px;
}

#customizeModal .checkbox-container label input {
  align-self: flex-end;
}

#customizeModal .button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#customizeModal .modal-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

#customizeModal .save {
  background-color: #5C41BF;
}

#customizeModal .reject-all {
  background-color: #ff4d4d;
}

#customizeModal .accept-all {
  background-color: #5CB85C;
}

#customizeModal .modal-button:hover {
  opacity: 0.8;
}
