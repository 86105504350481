  /*pie de pagina*/

  footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-content .logo a,
  .footer-content nav a,
  .footer-content .legal a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-content nav ul {
    list-style: none;
    padding: 0;
    display: flex;
  }
  
  .footer-content nav li {
    margin: 0 10px;
  }
  
  .footer-content .legal {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
  }
  .footer-content .legal a{
    padding-top: 10px;
  }
  
  .footer-content .copyright {
    font-size: 14px;
  }

  @media (max-width: 600px){
    footer{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .footer-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .footer-content ul{
      display: flex;
      flex-direction: column;
      padding: 50px;
      justify-content: center;
      align-items: center;
    }
    .footer-content li{
      padding: 5px;
    }
    .footer-content li a:hover{
      color: blue !important;
    }
    .footer-content .logo{
      font-size: 50px;
    }
    .footer-content nav, .footer-content .logo, .footer-content .legal, .footer-content .copyright{
      margin: 25px;
      text-align: center;
    }
    .legal{
      text-align: center;
    }
    .legal a:hover{
      color: blue;
    }
  }


.politica-modal {
  display: none; 
  position: fixed; 
  z-index: 9999; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); 
  text-align: justify;
}

.politica-modal-content {
  background-color: #333;
  color: #fff; 
  margin: 15% auto;
  padding: 20px;
  border-radius: 8px;
  border: none;
  width: 90%; 
  max-width: 600px; 
}


.politica-close {
  color: #aaa; 
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.politica-close:hover,
.politica-close:focus {
  color: #fff; 
  text-decoration: none; 
  cursor: pointer; 
}
#openPoliticaModal{
  cursor: pointer;
}

.direcciones{
  margin-top: 35px;
  font-size: 12px;
}
.direcciones a{
  color: rgb(61, 61, 248) !important;
}
@media screen and (max-width: 600px) {
  .politica-modal-content {
    margin: 10% auto;

  }
  .direcciones{
    margin-top: 35px;
    font-size: 12px;
  }
  .direcciones a{
    color: blue !important;
  }
  .legal a{
    margin-top: 10px;
  }
}
