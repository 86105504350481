.section {
background-color: #515151;
    height: auto;
    padding-top: 1px;
    padding-top: -50px;
    text-align: justify;
  }
  
  .section-content {
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
    max-width: 1300px;
    margin-top: -30px;
    width: 70%;
    padding: 20px;
    margin: -30px 0;
  }
  
  .section h2 {
    font-size: 2em;
    margin-bottom: 1em;
  }
  
  .section p {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 1em;
  }
  @media (max-width: 768px){
    .section h2 {
      font-size: 1.5rem;
      margin-bottom: 1em;
    }
    .section{
      padding-top: 40px;
    }
    .section-content {
      background-color: #fff;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
      margin-top: -60px;
      width: 70%;

      margin: 0px 0;
    }
  }