.blog-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    margin: 20px;
  }
  
  .post {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .post-image {
    height: 200px;
    background-position: center;
    background-size: cover;
  }
  
  .post-content {
    padding: 20px;
    color: black;
  }
  
  .post-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .post-date {
    font-size: 14px;
    color: #999;
    margin-bottom: 10px;
  }
  
  .post-excerpt {
    font-size: 16px;
    line-height: 1.5;
  }
  .post-link {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .post-link:hover {
    text-decoration: underline;
  }
  