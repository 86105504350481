.banner-medio {
  height: 50vh;
  background-size: cover;
  display: flex;  
  justify-content: center;
  align-items: center;
}

h1 {
  color: white;
  font-size: 5rem;
  text-shadow: black 0.1em 0.1em 0.2em
}
@media (max-width: 600px){
  h1{
    font-size: 3rem;
  }
}