.privacy-policy {
    padding: 60px;
    font-family: sans-serif;
    color: #333;
    line-height: 1.6;
}

.privacy-policy h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #0056b3;
}

.privacy-policy h2 {
    font-size: 1.8em;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #003366;
}

.privacy-policy p {
    margin-bottom: 10px;
}

.privacy-policy ul {
    list-style-type: disc;
    padding-left: 20px;
}

.privacy-policy a {
    color: #0056b3;
    text-decoration: none;
}

.privacy-policy a:hover {
    text-decoration: underline;
}