.container {
    width: 100%;
    margin: auto;
    text-align: center;
    padding-top: 20px;
    background: rgb(22,29,29);
    background: linear-gradient(259deg, rgba(22,29,29,1) 0%, rgba(253,187,45,1) 100%);
    background-size: cover;
    background-position: center;
}

.container h2{
    font-size: 67px;
    color: #000;
    text-align: center;
    text-shadow: 1px 3px 0 #b8b8b8bb, 1px 10px 5px #5d5d5d;
}

.card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 0 15px;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: calc(25% - 30px);
    margin: 15px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 100%;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card h3 {
    margin-top: 0;
    font-size: 25px;
    color: rgb(0, 0, 0);
}

.card a {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    text-align: center;
    background-color: rgb(0, 60, 139);
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.card a:hover {
    background-color: rgb(0, 80, 159);
}

.card .tittle-dos{
    margin: 10px 0;
    font-size: 18px;

}

.card p{
    margin: 10px 0;
}

@media (max-width: 600px) {
    .card {
        width: calc(100% - 30px);
    }
    .container h2{
      font-size: 40px;
    }
    .container{
        height: auto;
    }
}

@media (max-width: 400px) {
    .card {
        width: 100%;
    }
}
