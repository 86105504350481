.contact-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #b5b5b5;
    padding: 60px 160px 60px 160px;
  }
.contact-section h3{
  font-size: 30px;
  margin-top: 0px;
}
  
  .contact-form{
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    padding: 50px;
    width: calc(50% - 10px);
    
  }
  .info-card {
    background-color: #e4e3e3;
    border-radius: 0px 10px 10px 0px;
    box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.5);
    padding: 50px;
    width: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 450px;
    
  }
  .info-card img {
    width: 100%;
    height: 80%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .info-card p {
    margin-top: 20px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .privacyPolicy{
    display: flex;
  }
  #privacyPolicy{
    width: 10px;
    height: 10px;
    padding: 0px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 90%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .contact-form button {
    color: #fff;
    border: none;
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .contact-form button:hover{
    color: #cacaca;
    border: none;
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  @media (max-width: 600px){
    .info-card{
      display: none;
    }
    .contact-form{
      background-color: #f0f0f0;
      border-radius: 10px;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
      padding: 25px;
      margin: 20px;
      width: 100%;
      
    }
    .contact-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #b5b5b5;
      padding: 0px;
    }

  }
