/*nuestros servicios*/
.servicios{
  background-color: #515151;
}
.services-list{
  display: flex;
  justify-content: space-around;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 15px;
}
.contenedor-servicios{
padding-bottom: 30px;
background-color: #515151;
padding-top: 50px;
padding-bottom: 60px;
}

.contenedor-servicios h2{
  font-size: 67px;
  color: #000000;
  text-align: center;
  text-shadow: 1px 3px 0 #b8b8b8bb, 1px 10px 5px #5d5d5d;

}

.services-list a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(40% - 10px);
  padding: 10px;
  margin: 10px;
  text-decoration: none;
  border: 1px solid black;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  font-size: 22px;
  font-weight: bold;
  color: #333;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.services-list a:hover {
  background-color: #ddd;
  color: white;
  transform: scale(1.05);
}

@media (max-width: 600px) {
  .contenedor-servicios{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 110%;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 20px;
    margin-top: 0px;
    padding-bottom: 40px;
  }
  .services-list{
    width: 100%;
  }
  .services-list a {
      width: 100%;
  }
  .contenedor-servicios h2{
    margin-top: 50px;
    font-size: 45px;
  }
}